import { TextField, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function copyToClipBoard(text: string) {
  return navigator.clipboard.writeText(text);
}

export function GaAuthorizationCode() {
  const params = new URLSearchParams(useLocation().search);
  const code = params.get("code");
  const [copied, setCopied] = useState(false);

  if (!code) {
    return (
      <div>
        認可コードが取得できませんでした。このタブを閉じてもう一度お試しください。
      </div>
    );
  }

  if (!navigator.clipboard) {
    return <div>Please use supported browsers.</div>;
  }

  return (
    <div>
      下記の認可コードをコピーしてCV連携の設定欄に貼りつけてください。
      <p>
        <Tooltip
          title={copied ? "Copied!" : "Click text box to copy"}
          placement="left"
          arrow={true}
        >
          <TextField
            label="認可コード (1回クリックするとコピーされます)"
            variant="filled"
            value={code}
            style={{ width: 500 }}
            helperText={copied ? "Copied!" : "Click text box to copy"}
            onClick={() => copyToClipBoard(code).then(() => setCopied(true))}
            onMouseEnter={() => setCopied(false)}
          />
        </Tooltip>
      </p>
    </div>
  );
}
