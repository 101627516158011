import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./app/store";

interface MessageState {
  messages: string[];
}

const initialState: MessageState = {
  messages: [],
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string>) => {
      const set = new Set(state.messages);
      set.add(action.payload);
      state.messages = Array.from(set);
    },
    deleteMessage: (state, action: PayloadAction<string>) => {
      const set = new Set(state.messages);
      set.delete(action.payload);
      state.messages = Array.from(set);
    },
    resetMessage: () => {
      return initialState;
    },
  },
});

export const { setMessage, deleteMessage, resetMessage } =
  messagesSlice.actions;

export const selectMessages = (state: RootState) => state.messages;

export const messagesReducer = messagesSlice.reducer;
