import { FieldMetaState } from "react-final-form";

export const isError = <T>(meta: FieldMetaState<T>) =>
  (meta.touched && meta.invalid) ||
  (meta.visited && meta.invalid && meta.modified) ||
  (meta.invalid && !meta.pristine);

export const helperText = <T>(meta: FieldMetaState<T>, text?: string) =>
  isError(meta) ? meta.error : text ?? " ";

export const shrink = <T>(meta: FieldMetaState<T>) => meta.active || meta.valid;

export const startIconColor = <T>(meta: FieldMetaState<T>) =>
  isError(meta) ? "error" : meta.active ? "primary" : "action";
