import { isAdmin } from "../config";

export const required = (value: string | number) =>
  value || value === 0 ? undefined : "必須項目です";

export const isNumber = (value: string) =>
  /^[0-9]*$/.test(value) ? undefined : "数字を入力してください";

export const containedUser = (value: string, userId: string) => {
  const isValid =
    value
      .split(",")
      .map((id) => id.trim())
      .some((id) => id === userId) || isAdmin;

  return isValid ? undefined : `${userId}を含めてください`;
};

export const isUserIds = (value: string) => {
  const isValid = value
    .split(",")
    .map((id) => id.trim())
    .every((id) => /^[a-z]+\.[a-z]+$/.test(id));

  return isValid ? undefined : "ユーザーIDの形式が正しくありません";
};

// アムズ画面での入力可能値は2021年現在は半角英数記号のみだが、
// 古いアカウントではそれ以外の文字を使用している場合もある
export const isToolAccountId = (value: string) =>
  /^[^\t\r\n\v\f]+$/.test(value)
    ? undefined
    : "使用できない文字が含まれています";

export const isResultPoint = (value: string) =>
  /^[^\t\r\n\v\f]+$/.test(value)
    ? undefined
    : "使用できない文字が含まれています";

export const isAdebisConversion = (value: string) =>
  /^[^\t\r\n\v\f]+$/.test(value)
    ? undefined
    : "使用できない文字が含まれています (制御文字の入力が必要な場合は開発チームにお問い合わせください)";
