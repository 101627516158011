import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpPost } from "../../common/httpClient";
import { Status } from "../../common/status";
import { redirectTo } from "../../routerSlice";
import { fetchMastersList } from "./mastersListSlice";

interface MasterCreateState {
  status: Status;
}

interface Owner {
  userId: string;
}

interface MasterCreateRequest {
  masterName: string;
  masterType: number;
  owners: Owner[];
}

const initialState: MasterCreateState = {
  status: "idle",
};

export const createMaster = createAsyncThunk<
  unknown,
  MasterCreateRequest,
  ThunkApi
>("masters/createMaster", async (request, thunkApi) => {
  const path = "/masters";
  const response = await httpPost<unknown>(path, request, thunkApi);
  void thunkApi.dispatch(fetchMastersList());
  thunkApi.dispatch(redirectTo("/masters"));

  return response;
});

export const masterCreateSlice = createSlice({
  name: "masterCreate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMaster.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createMaster.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(createMaster.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectMasterCreate = (state: RootState) => state.masterCreate;

export const masterCreateReducer = masterCreateSlice.reducer;
