import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpGet } from "../../common/httpClient";
import { Status } from "../../common/status";
import {
  AdebisSetting,
  AlertDestination,
  GaSetting,
  SettingGetBase,
  SettingOwner,
} from "./settingTypes";

interface GaSettingShowResponse extends SettingGetBase {
  amsToolId: 23;
  settingOwners: SettingOwner[];
  alertDestinations: AlertDestination[];
  gaSetting: GaSetting;
}

interface AdebisSettingShowResponse extends SettingGetBase {
  amsToolId: 9;
  settingOwners: SettingOwner[];
  alertDestinations: AlertDestination[];
  adebisSetting: AdebisSetting;
}

export type SettingShowResponse =
  | GaSettingShowResponse
  | AdebisSettingShowResponse;

interface SettingShowRequest {
  id: number;
}

interface SettingShowState {
  status: Status;
  response?: SettingShowResponse;
}

const initialState: SettingShowState = {
  status: "idle",
};

export const fetchSettingShow = createAsyncThunk<
  SettingShowResponse,
  SettingShowRequest,
  ThunkApi
>("settings/fetchSettingShow", async (request, thunkApi) => {
  const { id } = request;
  const path = `/settings/${id}`;

  return httpGet<SettingShowResponse>(path, {}, thunkApi);
});

export const settingShowSlice = createSlice({
  name: "settingShow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettingShow.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchSettingShow.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.response = action.payload;
    });
    builder.addCase(fetchSettingShow.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectSettingShow = (state: RootState) => state.settingShow;

export const settingShowReducer = settingShowSlice.reducer;
