import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpDelete } from "../../common/httpClient";
import { Status } from "../../common/status";
import { redirectTo } from "../../routerSlice";
import { fetchSettingsList } from "./settingsListSlice";

interface SettingDeleteRequest {
  id: number;
}

interface SettingDeleteState {
  status: Status;
}

const initialState: SettingDeleteState = {
  status: "idle",
};

export const deleteSetting = createAsyncThunk<
  unknown,
  SettingDeleteRequest,
  ThunkApi
>("settings/deleteSetting", async (request, thunkApi) => {
  const { id } = request;
  const path = `/settings/${id}`;
  const response = await httpDelete<unknown>(path, thunkApi);
  void thunkApi.dispatch(fetchSettingsList());
  thunkApi.dispatch(redirectTo("/settings"));

  return response;
});

export const settingDeleteSlice = createSlice({
  name: "settingDelete",
  initialState,
  reducers: {
    resetSettingDeleteState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteSetting.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deleteSetting.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(deleteSetting.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectSettingDelete = (state: RootState) => state.settingDelete;

export const resetSettingDeleteState =
  settingDeleteSlice.actions.resetSettingDeleteState;

export const settingDeleteReducer = settingDeleteSlice.reducer;
