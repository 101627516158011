import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./app/store";
import { httpGet } from "./common/httpClient";
import { Status } from "./common/status";

interface CsrfResponse {
  token: string;
  headerName: string;
}

interface CsrfState {
  status: Status;
  response?: CsrfResponse;
}

const initialState: CsrfState = {
  status: "idle",
};

export const fetchCsrfToken = createAsyncThunk(
  "csrf/fetchCsrfToken",
  async (request, thunkApi) => {
    const path = "/csrf";

    return httpGet<CsrfResponse>(path, {}, thunkApi);
  },
);

export const csrfSlice = createSlice({
  name: "csrf",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCsrfToken.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchCsrfToken.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.response = action.payload;
    });
    builder.addCase(fetchCsrfToken.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectCsrf = (state: RootState) => state.csrf;

export const csrfReducer = csrfSlice.reducer;
