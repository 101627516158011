import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

import { csrfReducer } from "../csrfSlice";
import { logoutReducer } from "../features/layout/logoutSlice";
import { loginReducer } from "../features/login/loginSlice";
import { masterCreateReducer } from "../features/masters/masterCreateSlice";
import { masterDeleteReducer } from "../features/masters/masterDeleteSlice";
import { masterShowReducer } from "../features/masters/masterShowSlice";
import { mastersListReducer } from "../features/masters/mastersListSlice";
import { masterUpdateReducer } from "../features/masters/masterUpdateSlice";
import { manualExecutionReducer } from "../features/settings/manualExecutionSlice";
import { settingCreateReducer } from "../features/settings/settingCreateSlice";
import { settingDeleteReducer } from "../features/settings/settingDeleteSlice";
import { settingShowReducer } from "../features/settings/settingShowSlice";
import { settingsListReducer } from "../features/settings/settingsListSlice";
import { settingUpdateReducer } from "../features/settings/settingUpdateSlice";
import { messagesReducer } from "../messagesSlice";
import { routerReducer } from "../routerSlice";

const middleware = getDefaultMiddleware();

if (process.env.NODE_ENV === "development") {
  const loggerMiddleware = createLogger({ collapsed: true, diff: true });
  middleware.push(loggerMiddleware);
}

export const store = configureStore({
  reducer: {
    router: routerReducer,
    csrf: csrfReducer,
    messages: messagesReducer,
    login: loginReducer,
    logout: logoutReducer,
    mastersList: mastersListReducer,
    masterShow: masterShowReducer,
    masterCreate: masterCreateReducer,
    masterUpdate: masterUpdateReducer,
    masterDelete: masterDeleteReducer,
    manualExecution: manualExecutionReducer,
    settingsList: settingsListReducer,
    settingShow: settingShowReducer,
    settingCreate: settingCreateReducer,
    settingUpdate: settingUpdateReducer,
    settingDelete: settingDeleteReducer,
  },
  middleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export interface ThunkApi {
  dispatch: typeof store.dispatch;
  state: RootState;
}
