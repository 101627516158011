import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { DoneOutlineOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { helperText, isError } from "../../common/textFieldProps";
import { resetMessage } from "../../messagesSlice";
import {
  manualExecution,
  resetManualExecutionState,
  start,
} from "./manualExecutionSlice";

interface ExecDialogProps {
  open: boolean;
  handleClose: () => void;
  id: number;
  name: string;
  amsToolId: number;
}

interface SettingStartForm {
  startDate: string;
  endDate: string;
  id: number;
}

type errorForms = {
  startDate?: string;
  endDate?: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    span: {
      verticalAlign: "middle",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    form: {
      width: "100%",
      padding: "5px",
    },
    formControl: {
      width: "100%",
    },
    formBox: {
      margin: "auto",
    },
  }),
);

export function ExecDialog(props: ExecDialogProps) {
  const { open, handleClose, id, name, amsToolId } = props;
  const dispatch = useDispatch();
  const { status: startStatus } = useSelector(manualExecution);

  const classes = useStyles();
  const endDate = dayjs().locale("ja").subtract(1, "d").format("YYYY-MM-DD");

  let startDate: string;

  if (amsToolId === 23) {
    startDate = dayjs().locale("ja").subtract(3, "d").format("YYYY-MM-DD");
  } else {
    startDate = dayjs().locale("ja").subtract(1, "d").format("YYYY-MM-DD");
  }

  const required = (value: string) => (value ? undefined : "必須項目です");

  useEffect(() => {
    if (startStatus === "succeeded") {
      handleClose();
      dispatch(resetManualExecutionState());
      dispatch(resetMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startStatus, dispatch]);

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <Form<SettingStartForm>
        initialValues={{
          id,
          startDate,
          endDate,
        }}
        onSubmit={({ startDate, endDate, id }) => {
          dispatch(start({ startDate, endDate, id }));
        }}
        validate={(values) => {
          let errors: errorForms = {};

          if (
            !dayjs(values.endDate + "23:59:59")
              .locale("ja")
              .isBefore(dayjs().locale("ja"))
          ) {
            errors.endDate = "本日よりも前の日付を設定してください";
          }

          if (
            Object.keys(errors).length === 0 &&
            values.startDate > values.endDate
          ) {
            errors = {
              startDate: "取得開始日を取得終了日よりも前に設定してください",
              endDate: "取得開始日を取得終了日よりも前に設定してください",
            };
          }

          if (
            Object.keys(errors).length === 0 &&
            dayjs(values.endDate).diff(dayjs(values.startDate), "day") >= 10
          ) {
            errors = {
              startDate: "指定できる期間は最大10日間です",
              endDate: "指定できる期間は最大10日間です",
            };
          }

          return errors;
        }}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <DialogTitle id="dialog-title" disableTypography>
              <Box display="flex">
                <Box>
                  <DoneOutlineOutlined fontSize={"small"} />
                </Box>
                <Box fontSize={"16px"}>
                  {"今すぐ実行："}
                  {name}
                </Box>
              </Box>
            </DialogTitle>

            <DialogContent>
              <FormControl className={classes.formControl}>
                <Box className={classes.formBox}>
                  <Field<string>
                    name="startDate"
                    validate={required}
                    render={({ input, meta }) => {
                      return (
                        <TextField
                          {...input}
                          label="対象期間(from)"
                          type="date"
                          error={isError(meta)}
                          helperText={helperText(meta)}
                          defaultValue={startDate}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      );
                    }}
                  />
                  <span className={classes.span}>〜</span>
                  <Field<string>
                    name="endDate"
                    validate={required}
                    render={({ input, meta }) => {
                      return (
                        <TextField
                          {...input}
                          label="対象期間(to)"
                          type="date"
                          error={isError(meta)}
                          helperText={helperText(meta)}
                          defaultValue={endDate}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      );
                    }}
                  />
                </Box>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button
                type="submit"
                color="primary"
                disabled={hasValidationErrors || startStatus === "loading"}
                disableFocusRipple={true}
              >
                実行する
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}
