import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import { DoneOutline } from "@material-ui/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteMaster,
  resetMasterDeleteState,
  selectMasterDelete,
} from "./masterDeleteSlice";

interface DeleteDialogProps {
  open: boolean;
  id: number;
  handleClose: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      verticalAlign: "middle",
      paddingRight: theme.spacing(1),
    },
  }),
);

export function DeleteDialog(props: DeleteDialogProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { status } = useSelector(selectMasterDelete);
  const { open, id, handleClose } = props;

  const handleSubmit = () => {
    dispatch(deleteMaster({ id }));
  };

  useEffect(() => {
    if (status === "succeeded") {
      handleClose();
      dispatch(resetMasterDeleteState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dispatch]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <DoneOutline className={classes.icon} />
        CV連携マスタ：削除確認
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          このCV連携マスタを削除しますか？
          <br />
          ※このCV連携マスタを使用しているCV連携設定があると削除できません
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={status === "loading"}>
          キャンセル
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={status === "loading" || status === "succeeded"}
        >
          削除する
        </Button>
      </DialogActions>
      {status === "loading" ? <LinearProgress /> : undefined}
    </Dialog>
  );
}
