import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpDelete } from "../../common/httpClient";
import { Status } from "../../common/status";
import { redirectTo } from "../../routerSlice";
import { fetchMastersList } from "./mastersListSlice";

interface MasterDeleteRequest {
  id: number;
}

interface MasterDeleteState {
  status: Status;
}

const initialState: MasterDeleteState = {
  status: "idle",
};

export const deleteMaster = createAsyncThunk<
  unknown,
  MasterDeleteRequest,
  ThunkApi
>("masters/deleteMaster", async (request, thunkApi) => {
  const { id } = request;
  const path = `/masters/${id}`;
  const response = await httpDelete<unknown>(path, thunkApi);
  void thunkApi.dispatch(fetchMastersList());
  thunkApi.dispatch(redirectTo("/masters"));

  return response;
});

export const masterDeleteSlice = createSlice({
  name: "masterDelete",
  initialState,
  reducers: {
    resetMasterDeleteState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteMaster.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deleteMaster.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(deleteMaster.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectMasterDelete = (state: RootState) => state.masterDelete;

export const resetMasterDeleteState =
  masterDeleteSlice.actions.resetMasterDeleteState;

export const masterDeleteReducer = masterDeleteSlice.reducer;
