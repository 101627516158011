import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  AddCircleOutline,
  CheckBoxOutlined,
  DeleteOutline,
  NotesOutlined,
  PeopleOutlined,
} from "@material-ui/icons";
import { useEffect } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";

import {
  helperText,
  isError,
  shrink,
  startIconColor,
} from "../../common/textFieldProps";
import {
  containedUser,
  isAdebisConversion,
  isResultPoint,
  isToolAccountId,
  isUserIds,
  required,
} from "../../common/validation";
import { USER_ID_KEY } from "../login/loginSlice";
import {
  fetchMastersList,
  selectMastersList,
} from "../masters/mastersListSlice";
import { selectSettingCreate } from "./settingCreateSlice";
import { selectSettingShow } from "./settingShowSlice";
import type { CvType, SettingFormBase } from "./settingTypes";
import { selectSettingUpdate } from "./settingUpdateSlice";

export interface AdebisFormType extends SettingFormBase {
  amsToolId: 9;
  amsToolAccountId: string;
  adebisCvs: Cv[];
  masterId: number;
  settingOwners: string;
  alertDestinations: string;
}

interface Cv {
  adebisCvMetric: string;
  resultPointName: string;
  type: CvType;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    containerItem: {
      padding: theme.spacing(2),
    },
    equal: {
      fontSize: "40px",
      fontWeight: "lighter",
      paddingLeft: theme.spacing(2),
    },
  }),
);

interface MetricRowProps {
  name: string;
  handleDelete?: () => void;
}

function MetricRow(props: MetricRowProps) {
  const classes = useStyles();
  const { name, handleDelete } = props;
  const { status: settingCreateStatus } = useSelector(selectSettingCreate);
  const { status: settingUpdateStatus } = useSelector(selectSettingUpdate);

  return (
    <Grid container alignItems="center">
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Grid container alignItems="center">
          <Grid item>
            <NotesOutlined />
          </Grid>
          <Grid item xs={9}>
            <Field<string>
              name={`${name}.adebisCvMetric`}
              validate={(value) => required(value) ?? isAdebisConversion(value)}
              render={({ input, meta }) => (
                <TextField
                  {...input}
                  label="コンバージョン名"
                  error={isError(meta)}
                  helperText={helperText(meta)}
                  fullWidth
                  InputLabelProps={{ shrink: shrink(meta) }}
                  disabled={
                    settingCreateStatus === "loading" ||
                    settingUpdateStatus === "loading"
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <span className={classes.equal}>=</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Field<string>
          name={`${name}.resultPointName`}
          validate={(value) => required(value) ?? isResultPoint(value)}
          render={({ input, meta }) => (
            <TextField
              {...input}
              label="CV地点名"
              error={isError(meta)}
              helperText={helperText(meta)}
              fullWidth
              InputLabelProps={{ shrink: shrink(meta) }}
              disabled={
                settingCreateStatus === "loading" ||
                settingUpdateStatus === "loading"
              }
            />
          )}
        />
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Field<string>
          name={`${name}.type`}
          validate={required}
          render={({ input, meta }) => (
            <TextField
              {...input}
              label="CV地点タイプ"
              select
              error={isError(meta)}
              helperText={helperText(meta)}
              fullWidth
              InputLabelProps={{ shrink: shrink(meta) }}
              disabled={
                settingCreateStatus === "loading" ||
                settingUpdateStatus === "loading"
              }
            >
              <MenuItem value="cv_t">件数（のべ）</MenuItem>
              <MenuItem value="cv_u">件数（ユニーク）</MenuItem>
            </TextField>
          )}
        />
      </Grid>
      {handleDelete ? (
        <Grid className={classes.containerItem} item xs={12} sm={3}>
          <Button
            variant="contained"
            startIcon={<DeleteOutline />}
            onClick={handleDelete}
            disabled={
              settingCreateStatus === "loading" ||
              settingUpdateStatus === "loading"
            }
          >
            削除
          </Button>
        </Grid>
      ) : undefined}
    </Grid>
  );
}

interface AdebisFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  push: (...args: any[]) => any;
  isEdit?: boolean;
  initialized?: boolean;
}

export function AdebisForm(props: AdebisFormProps) {
  const { status, response } = useSelector(selectMastersList);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { push, isEdit, initialized } = props;
  const masters = response?.filter(({ type }) => type === 2 || type === 3);
  const userId = localStorage.getItem(USER_ID_KEY) ?? "";
  const { status: settingCreateStatus } = useSelector(selectSettingCreate);
  const { status: settingUpdateStatus } = useSelector(selectSettingUpdate);
  const { response: showResponse } = useSelector(selectSettingShow);

  const handleAddMetricButtonClick = () => {
    push("adebisCvs", undefined);
  };

  if (
    isEdit &&
    showResponse !== undefined &&
    masters !== undefined &&
    !masters.map((x) => x.id).includes(showResponse.masterId)
  ) {
    masters.push({
      id: showResponse.masterId,
      name: "（権限のないマスタが設定されています）",
      masterUrl: "",
      type: 2,
    });
  }

  useEffect(() => {
    dispatch(fetchMastersList());
  }, [dispatch]);

  useEffect(() => {
    if (!isEdit && !initialized) {
      push("adebisCvs", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === "loading") {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<number>
            name="masterId"
            validate={required}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="CV連携マスタ"
                select
                error={isError(meta)}
                helperText={helperText(meta)}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <CheckBoxOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              >
                {!masters || masters.length === 0 ? (
                  <MenuItem disabled>指定可能なマスタがありません</MenuItem>
                ) : (
                  masters.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))
                )}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Divider />

      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="amsToolAccountId"
            validate={(value) => required(value) ?? isToolAccountId(value)}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="計測ツールアカウントID"
                error={isError(meta)}
                helperText={helperText(meta)}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <NotesOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Divider />

      <FieldArray name="adebisCvs">
        {({ fields }) =>
          fields.map((name, index) => (
            <MetricRow
              key={name}
              name={name}
              handleDelete={
                index === 0 ? undefined : () => fields.remove(index)
              }
            />
          ))
        }
      </FieldArray>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline />}
            onClick={handleAddMetricButtonClick}
            disabled={
              settingCreateStatus === "loading" ||
              settingUpdateStatus === "loading"
            }
          >
            CV地点を追加
          </Button>
        </Grid>
      </Grid>
      <Divider />

      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="settingOwners"
            defaultValue={userId}
            validate={(value) =>
              required(value) ??
              containedUser(value, userId) ??
              isUserIds(value)
            }
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="設定管理ユーザー"
                error={isError(meta)}
                helperText={helperText(meta, "カンマ区切りで複数入力可")}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <PeopleOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="alertDestinations"
            defaultValue={userId}
            validate={(value) => required(value) ?? isUserIds(value)}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="エラー通知先ユーザー"
                error={isError(meta)}
                helperText={helperText(meta, "カンマ区切りで複数入力可")}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <PeopleOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
