import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./app/store";

interface routerState {
  redirectTo?: string;
}

const initialState: routerState = {};

export const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    resetRedirectTo: (state) => {
      state.redirectTo = undefined;
    },
    redirectTo: (state, action: PayloadAction<string>) => {
      state.redirectTo = action.payload;
    },
  },
});

export const { resetRedirectTo, redirectTo } = routerSlice.actions;

export const selectRouter = (state: RootState) => state.router;

export const routerReducer = routerSlice.reducer;
