export const isProdBuild = process.env.NODE_ENV === "production";

export const isProdStage = process.env.REACT_APP_STAGE === "production";

export const isStgStage = process.env.REACT_APP_STAGE === "staging";

export const isDevStage = process.env.REACT_APP_STAGE === "development";

export const isAdminStage = process.env.REACT_APP_STAGE === "admin";

export const isAdmin =
  isAdminStage || process.env.REACT_APP_STAGE === "admin-development";
