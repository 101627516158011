import {
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Favorite, Star } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import { AccountAndHelpButtons } from "../../common/AccountAndHelpButtons";
import { Footer } from "../../common/Footer";
import { isAdmin } from "../../config";
import { fetchCsrfToken, selectCsrf } from "../../csrfSlice";
import { MastersList } from "../masters/MastersList";
import { GaAuthorizationCode } from "../settings/GaAuthorizationCode";
import { SettingsList } from "../settings/SettingsList";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "white",
    color: "darkblue",
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.default,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    backgroundColor: theme.palette.background.default,
  },
  underToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    left: theme.spacing(7),
    right: theme.spacing(0),
    position: "absolute",
  },
  sideNaviItem: {
    borderBottomRightRadius: "32px",
    borderTopRightRadius: "32px",
    margin: "4px 0",
    width: `calc(100% - 4px)`,
    height: "40px",
  },
}));

export function Layout() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { status: csrfStatus } = useSelector(selectCsrf);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (csrfStatus === "idle") {
      dispatch(fetchCsrfToken());
    }
  }, [csrfStatus, dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {/* ヘッダー */}
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            {"CVアップ自動化" + (isAdmin ? "管理画面" : "")}
          </Typography>
          <AccountAndHelpButtons />
        </Toolbar>
      </AppBar>

      <Switch>
        <Route exact path="/settings/auth">
          <main className={classes.content}>
            <div className={classes.underToolbar} />
            <GaAuthorizationCode />
          </main>
        </Route>
        <Route exact path="*">
          {/* サイドナビゲーション */}
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            onMouseOver={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
          >
            <div className={classes.underToolbar} />
            <List>
              <ListItem
                button
                key="masters"
                component={NavLink}
                exact
                to={"/masters"}
                className={classes.sideNaviItem}
                classes={{ root: classes.sideNaviItem }}
                activeStyle={{
                  background: theme.palette.action.selected,
                  color: theme.palette.action.disabled,
                }}
              >
                <ListItemIcon>
                  <Favorite />
                </ListItemIcon>
                <ListItemText disableTypography>CV連携マスタ</ListItemText>
              </ListItem>
              <ListItem
                button
                key="settings"
                component={NavLink}
                exact
                to={"/settings"}
                className={classes.sideNaviItem}
                classes={{ root: classes.sideNaviItem }}
                activeStyle={{
                  background: theme.palette.action.selected,
                  color: theme.palette.action.disabled,
                }}
              >
                <ListItemIcon>
                  <Star />
                </ListItemIcon>
                <ListItemText disableTypography>CV連携リスト</ListItemText>
              </ListItem>
            </List>
          </Drawer>

          {/* コンテンツ */}
          <main className={classes.content}>
            <div className={classes.underToolbar} />
            <Switch>
              <Route exact path="/">
                <Redirect to="/masters" />
              </Route>
              <Route exact path="/settings">
                <SettingsList />
              </Route>
              <Route exact path="/settings/new">
                <SettingsList />
              </Route>
              <Route exact path="/settings/:id/edit">
                <SettingsList />
              </Route>
              <Route exact path="/masters">
                <MastersList />
              </Route>
              <Route exact path="/masters/new">
                <MastersList />
              </Route>
              <Route exact path="/masters/:id/edit">
                <MastersList />
              </Route>
              <Route exact path="*">
                <NoMatch />
              </Route>
            </Switch>
          </main>
        </Route>
      </Switch>

      {/* フッター */}
      <Footer />
    </div>
  );
}

function NoMatch() {
  const location = useLocation();

  return (
    <Typography>
      {location.pathname} に該当するページは存在しません。
    </Typography>
  );
}
