import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpGet } from "../../common/httpClient";
import { Status } from "../../common/status";

interface MasterShowRequest {
  id: number;
}

interface Owner {
  userId: string;
}

interface MasterShowResponse {
  id: number;
  name: string;
  masterUrl: string;
  type: number;
  owners: Owner[];
}

interface MasterShowState {
  status: Status;
  response?: MasterShowResponse;
}

const initialState: MasterShowState = {
  status: "idle",
};

export const fetchMasterShow = createAsyncThunk<
  MasterShowResponse,
  MasterShowRequest,
  ThunkApi
>("masters/fetchMasterShow", async (request, thunkApi) => {
  const { id } = request;
  const path = `/masters/${id}`;

  return httpGet<MasterShowResponse>(path, {}, thunkApi);
});

export const masterShowSlice = createSlice({
  name: "masterShow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMasterShow.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchMasterShow.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.response = action.payload;
    });
    builder.addCase(fetchMasterShow.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectMasterShow = (state: RootState) => state.masterShow;

export const masterShowReducer = masterShowSlice.reducer;
