import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  AddCircleOutline,
  ArrowBackIos,
  ArrowForwardIos,
  EditOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setDocumentTitle } from "../../common/setTitle";
import { MasterCreate } from "./MasterCreate";
import {
  fetchMastersList,
  MasterResponse,
  selectMastersList,
} from "./mastersListSlice";
import { masterTypes } from "./masterType";
import { MasterUpdate } from "./MasterUpdate";

const useEnhancedTableHeadStyles = makeStyles((theme) =>
  createStyles({
    edit: {
      minWidth: "100px",
    },
    text: {
      minWidth: "200px",
    },
    status: {
      minWidth: "120px",
    },
    run: {
      minWidth: "200px",
    },
    url: {
      minWidth: "400px",
      width: "400px",
    },
    head: {
      fontSize: ".75rem",
      fontWeight: "bold",
    },
  }),
);

function EnhancedTableHead() {
  const classes = useEnhancedTableHeadStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.edit, classes.head)} align="center">
          操作
        </TableCell>
        <TableCell className={clsx(classes.text, classes.head)} align="left">
          CV連携マスタ名
        </TableCell>
        <TableCell className={clsx(classes.text, classes.head)} align="left">
          マスタ種別
        </TableCell>
        <TableCell className={clsx(classes.url, classes.head)} align="left">
          スプレッドシートURL
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface PageMenuProps {
  from: number;
  to: number;
  count: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  handleFirstPageButtonClick: MouseEventHandler<HTMLLIElement>;
  handleLastPageButtonClick: MouseEventHandler<HTMLLIElement>;
}

const usePageMenuStyles = makeStyles((theme) =>
  createStyles({
    span: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    button: {
      fontWeight: "normal",
    },
  }),
);

function PageMenu(props: PageMenuProps) {
  const {
    from,
    to,
    count,
    isFirstPage,
    isLastPage,
    handleFirstPageButtonClick,
    handleLastPageButtonClick,
  } = props;

  const classes = usePageMenuStyles();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span className={classes.span} onMouseLeave={handleClose}>
      <Button className={classes.button} ref={ref} onMouseOver={handleOpen}>
        {`${from}-${to} ｜ ${count} 件`}
      </Button>
      <Popper open={Boolean(ref.current)} anchorEl={ref.current}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} in={open}>
            <Paper>
              <MenuList>
                <MenuItem
                  disabled={isFirstPage}
                  onClick={handleFirstPageButtonClick}
                >
                  最初
                </MenuItem>
                <MenuItem
                  disabled={isLastPage}
                  onClick={handleLastPageButtonClick}
                >
                  最後
                </MenuItem>
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
    </span>
  );
}

interface PaperHeaderProps {
  from: number;
  to: number;
  count: number;
  rowsPerPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

function PaperHeader(props: PaperHeaderProps) {
  const history = useHistory();
  const { from, to, count, rowsPerPage, page, setPage } = props;
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
  const isFirstPage = page === 0;
  const isLastPage = page === lastPage;

  const handleOpenMasterCreate = () => {
    history.push("/masters/new");
  };

  const handleBackPageButtonClick = () => {
    setPage(Math.max(0, page - 1));
  };

  const handleNextPageButtonClick = () => {
    setPage(Math.min(lastPage, page + 1));
  };

  const handleFirstPageButtonClick = () => {
    setPage(0);
  };

  const handleLastPageButtonClick = () => {
    setPage(lastPage);
  };

  return (
    <Box marginBottom="24px">
      <Grid container justifyContent="space-between">
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={handleOpenMasterCreate}
        >
          CV連携マスタを作成
        </Button>
        <div>
          <PageMenu
            {...{
              from,
              to,
              count,
              isFirstPage,
              isLastPage,
              handleFirstPageButtonClick,
              handleLastPageButtonClick,
            }}
          />
          <span>
            {/* NOTE: Tooltip 直下に IconButton を設定すると以下の警告が出るが、無視して良い */}
            {/* Material-UI: You are providing a disabled `button` child to the Tooltip component. A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. Add a simple wrapper element, such as a `span`. */}
            <Tooltip title="前">
              <IconButton
                size="small"
                disabled={isFirstPage}
                onClick={handleBackPageButtonClick}
              >
                <ArrowBackIos fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </span>
          <span>
            <Tooltip title="次">
              <IconButton
                size="small"
                disabled={isLastPage}
                onClick={handleNextPageButtonClick}
              >
                <ArrowForwardIos fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </span>
        </div>
      </Grid>
    </Box>
  );
}

interface EnhancedTableRowProps {
  master: MasterResponse;
  index: number;
}

const useEnhancedTableRowStyles = makeStyles((theme) =>
  createStyles({
    truncate: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    textSpan: {
      display: "inline-block",
      maxWidth: "100%",
    },
    button: {
      minWidth: "30px",
      margin: theme.spacing(1),
    },
    urlButton: {
      maxWidth: "400px",
    },
    cell: {
      padding: "0 16px",
    },
  }),
);

function EnhancedTableRow(props: EnhancedTableRowProps) {
  const {
    master: { id, name, type, masterUrl },
    index,
  } = props;

  const classes = useEnhancedTableRowStyles();
  const history = useHistory();

  const handleOpenMasterEdit = () => {
    history.push(`/masters/${id}/edit`);
  };

  const masterTypeName = masterTypes.find(
    (masterType) => masterType.id === type,
  )?.name;

  if (masterTypeName === undefined) {
    throw new Error(`不明なマスタタイプ が指定されました。masterType=${type}`);
  }

  return (
    <TableRow hover key={index}>
      <TableCell align="center" className={classes.cell}>
        <Tooltip title="CV連携マスタを編集">
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            onClick={handleOpenMasterEdit}
          >
            <EditOutlined fontSize="small" />
          </Button>
        </Tooltip>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Tooltip title={name}>
          <span className={clsx(classes.textSpan, classes.truncate)}>
            {name}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Tooltip title={masterTypeName}>
          <span className={clsx(classes.textSpan, classes.truncate)}>
            {masterTypeName}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Tooltip title={masterUrl}>
          <Button
            href={masterUrl}
            target="_blank"
            rel="noopener"
            color="primary"
            size="small"
            className={classes.urlButton}
          >
            <span className={clsx(classes.textSpan, classes.truncate)}>
              {masterUrl}
            </span>
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(3),
    },
  }),
);

export function MastersList() {
  const { status, response } = useSelector(selectMastersList);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;
  const count = response?.length ?? 0;
  const from = count === 0 ? 0 : page * rowsPerPage + 1;
  const to = Math.min(count, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    dispatch(fetchMastersList());
  }, [dispatch]);

  useEffect(() => {
    setDocumentTitle("CV連携マスタ");
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        {status === "loading" ? (
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <PaperHeader {...{ from, to, count, rowsPerPage, page, setPage }} />
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead />
                <TableBody>
                  {response
                    ?.slice(from - 1, to)
                    ?.map((master, index) => (
                      <EnhancedTableRow key={index} {...{ master, index }} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
      <MasterCreate />
      <MasterUpdate />
    </>
  );
}
