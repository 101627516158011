/* eslint @typescript-eslint/no-explicit-any: 0 */

import { isAdmin, isAdminStage, isProdStage, isStgStage } from "../config";
import { setMessage } from "../messagesSlice";
import { redirectTo } from "../routerSlice";

// GetThunkAPI が redux-toolkit から export されていないので、最低限の型定義を自前でやっている
interface ThunkApi {
  getState: () => any;
  dispatch: (action: any) => any;
  rejectWithValue: (value: unknown) => any;
}

const basePath =
  isProdStage || isAdminStage
    ? "https://api.tool-cv.central-platform.jp/"
    : isStgStage
    ? "https://stg.api.tool-cv.central-platform.jp/"
    : "http://localhost:8080/";

//pathは'/'始まりとする
function getURL(path: string): URL {
  return new URL((isAdmin ? "admin" : "") + path, basePath);
}

const headersBase = {
  "Content-Type": "application/json",
  accept: "application/json",
};

function handleError(response: Response, thunkApi: ThunkApi) {
  if (response.ok) {
    return response;
  }

  switch (response.status) {
    case 400:
      thunkApi.dispatch(
        setMessage("リクエストが不正です。開発チームにお問い合わせください。"),
      );

      throw Error("BAD_REQUEST");
    case 401:
      thunkApi.dispatch(
        setMessage("認証に失敗しました。ログインし直してください。"),
      );
      thunkApi.dispatch(redirectTo("/login"));

      throw Error("UNAUTHORIZED");
    case 404:
      thunkApi.dispatch(setMessage("リソースを発見できませんでした。"));

      throw Error("NOT_FOUND");
    case 500:
      thunkApi.dispatch(
        setMessage(
          "サーバー上でエラーが発生しました。ブラウザをリロードしても直らない場合、開発チームにお問い合わせください。",
        ),
      );

      throw Error("INTERNAL_SERVER_ERROR");
    default:
      thunkApi.dispatch(
        setMessage(
          "不明なエラーが発生しました。ブラウザをリロードしても直らない場合、開発チームにお問い合わせください。",
        ),
      );

      throw Error("UNHANDLED_ERROR");
  }
}

async function fetchWithErrorHandling(
  input: RequestInfo,
  thunkApi: ThunkApi,
  init?: RequestInit,
) {
  return fetch(input, init).then((response) => handleError(response, thunkApi));
}

export async function httpGet<Body>(
  path: string,
  params: Record<string, any> = {},
  thunkApi: ThunkApi,
): Promise<Body> {
  const url = getURL(path);
  url.search = new URLSearchParams(params).toString();

  const response = await fetchWithErrorHandling(url.toString(), thunkApi, {
    mode: "cors",
    credentials: "include",
    headers: headersBase,
  });

  return (await response.json()) as Body;
}

export async function httpPost<Body>(
  path: string,
  body: Record<string, any> = {},
  thunkApi: ThunkApi,
): Promise<Body> {
  const token = thunkApi.getState().csrf.response?.token;

  if (!token) {
    return thunkApi.rejectWithValue("CSRF token is undefined.");
  }

  const url = getURL(path);

  const response = await fetchWithErrorHandling(url.toString(), thunkApi, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: { ...headersBase, "X-CSRF-TOKEN": token },
    body: JSON.stringify(body),
  });

  return (await response.json()) as Body;
}

export async function httpPut<Body>(
  path: string,
  body: Record<string, any> = {},
  thunkApi: ThunkApi,
): Promise<Body> {
  const token = thunkApi.getState().csrf.response?.token;

  if (!token) {
    return thunkApi.rejectWithValue("CSRF token is undefined.");
  }

  const url = getURL(path);
  const response = await fetchWithErrorHandling(url.toString(), thunkApi, {
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: { ...headersBase, "X-CSRF-TOKEN": token },
    body: JSON.stringify(body),
  });

  return (await response.json()) as Body;
}

export async function httpDelete<Body>(
  path: string,
  thunkApi: ThunkApi,
): Promise<Body> {
  const token = thunkApi.getState().csrf.response?.token;

  if (!token) {
    return thunkApi.rejectWithValue("CSRF token is undefined.");
  }

  const url = getURL(path);
  const response = await fetchWithErrorHandling(url.toString(), thunkApi, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
    headers: { ...headersBase, "X-CSRF-TOKEN": token },
  });

  return (await response.json()) as Body;
}
