import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpPut } from "../../common/httpClient";
import { Status } from "../../common/status";
import { redirectTo } from "../../routerSlice";
import { fetchSettingsList } from "./settingsListSlice";
import { AdebisSetting, GaSetting, SettingPostBase } from "./settingTypes";

interface SettingUpdateState {
  status: Status;
}

interface GaSettingUpdateRequest extends SettingPostBase {
  id: number;
  gaSetting: GaSetting;
}

interface AdebisSettingUpdateRequest extends SettingPostBase {
  id: number;
  adebisSetting: AdebisSetting;
}

type SettingUpdateRequest = GaSettingUpdateRequest | AdebisSettingUpdateRequest;

const initialState: SettingUpdateState = {
  status: "idle",
};

export const updateSetting = createAsyncThunk<
  unknown,
  SettingUpdateRequest,
  ThunkApi
>("settings/updateSetting", async (request, thunkApi) => {
  const { id } = request;
  const path = `/settings/${id}`;
  const response = await httpPut<unknown>(path, request, thunkApi);
  void thunkApi.dispatch(fetchSettingsList());
  thunkApi.dispatch(redirectTo("/settings"));

  return response;
});

export const settingUpdateSlice = createSlice({
  name: "settingUpdate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSetting.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateSetting.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(updateSetting.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectSettingUpdate = (state: RootState) => state.settingUpdate;

export const settingUpdateReducer = settingUpdateSlice.reducer;
