import { Box, Button, makeStyles } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.default,
    textAlign: "right",
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  contactFormButton: {
    backgroundColor: "gray",
    color: "white",
    borderRadius: 0,
    fontSize: "0.7rem",
    fontWeight: "bold",
  },
  contactFormButtonText: {
    marginLeft: "0.3rem",
  },
}));

export function Footer() {
  const classes = useStyles();

  return (
    <Box
      borderTop={1}
      borderColor={"rgba(0, 0, 0, 0.12)"}
      className={classes.footer}
    >
      <Button
        href="https://docs.google.com/forms/d/e/1FAIpQLScYRCvrWXZ391aEIxp2QSa-_ZAiP3zBRXyaQb-R-svErb65xw/viewform"
        target="_blank"
        rel="noopener noreferrer"
        size="small"
        className={classes.contactFormButton}
      >
        <MailOutline fontSize="small" />
        <span className={classes.contactFormButtonText}>
          ご意見・ご要望・お問い合わせ
        </span>
      </Button>
    </Box>
  );
}
