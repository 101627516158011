import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../app/store";
import { httpGet } from "../../common/httpClient";
import { Status } from "../../common/status";
import { SettingGetBase } from "./settingTypes";

export type SettingsListResponse = SettingGetBase;

interface SettingsListState {
  status: Status;
  response?: SettingsListResponse[];
}

const initialState: SettingsListState = {
  status: "idle",
};

export const fetchSettingsList = createAsyncThunk(
  "settings/fetchSettingsList",
  async (request, thunkApi) => {
    const path = "/settings";

    return httpGet<SettingsListResponse[]>(path, {}, thunkApi);
  },
);

export const settingsListSlice = createSlice({
  name: "settingsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettingsList.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchSettingsList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.response = action.payload;
    });
    builder.addCase(fetchSettingsList.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectSettingsList = (state: RootState) => state.settingsList;

export const settingsListReducer = settingsListSlice.reducer;
