import {
  createTheme,
  CssBaseline,
  IconButton,
  MuiThemeProvider,
  Snackbar,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { isAdmin } from "./config";
import { Layout } from "./features/layout/Layout";
import { Login } from "./features/login/login";
import { deleteMessage, selectMessages } from "./messagesSlice";
import { resetRedirectTo, selectRouter } from "./routerSlice";

// NOTE: Material-UI の一部のコンポーネントで findDOMNode の警告が出るが、現状だと対処のしようがない
// https://github.com/mui-org/material-ui/issues/13394

const themeLight = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    background: {
      default: isAdmin ? "#f3e5d4" : "#eceff1",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: "#607d8b",
        color: "white",
      },
    },
    MuiInputLabel: {
      root: {
        transform: "translate(24px, 24px) !important",
      },
      shrink: {
        transform: "translate(24px, 0px) scale(0.75) !important",
      },
    },
    MuiInput: {
      root: {
        caretColor: "#1976d2",
        "&.Mui-error": {
          caretColor: "red",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        maxWidth: "100%",
      },
    },
  },
});

export function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const { redirectTo } = useSelector(selectRouter);
  const { messages } = useSelector(selectMessages);

  useEffect(() => {
    if (redirectTo !== undefined && redirectTo !== pathname) {
      history.push(redirectTo);
      dispatch(resetRedirectTo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTo, history]);

  const handleCloseSnackbar = (key: string) => {
    dispatch(deleteMessage(key));
  };

  return (
    <MuiThemeProvider theme={themeLight}>
      <CssBaseline>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="">
            <Layout />
          </Route>
        </Switch>
        {messages.map((message) => (
          <Snackbar
            key={message}
            open
            message={message}
            action={
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handleCloseSnackbar(message)}
              >
                <Close fontSize="small" />
              </IconButton>
            }
          />
        ))}
      </CssBaseline>
    </MuiThemeProvider>
  );
}
