import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  ExitToApp,
  LockOutlined,
  PermIdentityOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { Footer } from "../../common/Footer";
import { setDocumentTitle } from "../../common/setTitle";
import {
  helperText,
  isError,
  shrink,
  startIconColor,
} from "../../common/textFieldProps";
import { required } from "../../common/validation";
import { isAdmin } from "../../config";
import { login, selectLogin, USER_ID_KEY } from "./loginSlice";

interface LoginForm {
  userId: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "480px",
    maxWidth: "100vw",
    padding: theme.spacing(2),
  },
  grid: {
    height: "100vh",
    padding: theme.spacing(1),
  },
  button: {
    fontWeight: "bold",
    backgroundColor: "#607d8b",
  },
}));

export function Login() {
  const { status: loginStatus } = useSelector(selectLogin);
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setDocumentTitle("ログイン");
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.grid}
    >
      <Paper component="div" className={classes.paper}>
        <Form<LoginForm>
          onSubmit={({ userId, password }) => {
            dispatch(login({ userId, password }));
            localStorage.setItem(USER_ID_KEY, userId);
          }}
          render={({ handleSubmit, hasValidationErrors, pristine }) => (
            <form onSubmit={handleSubmit}>
              <h3>{"CVアップ自動化" + (isAdmin ? "管理画面" : "")}</h3>
              <Grid container justifyContent="center" direction="row">
                <Grid item xs={12} md={12}>
                  <Box margin={1}>
                    <Field<string>
                      name="userId"
                      validate={required}
                      render={({ input, meta }) => (
                        <div>
                          <TextField
                            {...input}
                            label="ログインID［Windows ID］"
                            error={isError(meta)}
                            helperText={helperText(meta)}
                            fullWidth
                            disabled={loginStatus === "loading"}
                            InputLabelProps={{ shrink: shrink(meta) }}
                            InputProps={{
                              startAdornment: (
                                <PermIdentityOutlined
                                  color={startIconColor(meta)}
                                />
                              ),
                            }}
                          />
                        </div>
                      )}
                    />
                  </Box>
                  <Box margin={1}>
                    <Field<string>
                      name="password"
                      type={showPassword ? "text" : "password"}
                      validate={required}
                      render={({ input, meta }) => (
                        <div>
                          <TextField
                            {...input}
                            label="パスワード［Windows PW］"
                            error={isError(meta)}
                            helperText={helperText(meta)}
                            fullWidth
                            disabled={loginStatus === "loading"}
                            InputLabelProps={{ shrink: shrink(meta) }}
                            InputProps={{
                              startAdornment: (
                                <LockOutlined color={startIconColor(meta)} />
                              ),
                              endAdornment: (
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  size="small"
                                  color={
                                    isError(meta)
                                      ? "secondary"
                                      : meta.active
                                      ? "primary"
                                      : "default"
                                  }
                                  disableRipple={true}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlined />
                                  ) : (
                                    <VisibilityOffOutlined />
                                  )}
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                      )}
                    />
                  </Box>
                </Grid>
                {loginStatus === "loading" ? (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <CircularProgress size={30} />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={pristine || hasValidationErrors}
                      disableFocusRipple={true}
                      startIcon={<ExitToApp />}
                      className={classes.button}
                    >
                      ログイン
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        />
      </Paper>
      <Footer />
    </Grid>
  );
}
