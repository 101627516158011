import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpPut } from "../../common/httpClient";
import { Status } from "../../common/status";
import { redirectTo } from "../../routerSlice";
import { fetchMastersList } from "./mastersListSlice";

interface MasterUpdateState {
  status: Status;
}

const initialState: MasterUpdateState = {
  status: "idle",
};

interface Owner {
  userId: string;
}

interface MasterUpdateRequest {
  masterId: number;
  masterName: string;
  owners: Owner[];
}

export const updateMaster = createAsyncThunk<
  unknown,
  MasterUpdateRequest,
  ThunkApi
>("masters/updateMaster", async (request, thunkApi) => {
  const { masterId } = request;
  const path = `/masters/${masterId}`;
  const response = await httpPut<unknown>(path, request, thunkApi);
  void thunkApi.dispatch(fetchMastersList());
  thunkApi.dispatch(redirectTo("/masters"));

  return response;
});

export const masterUpdateSlice = createSlice({
  name: "masterUpdate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateMaster.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateMaster.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(updateMaster.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectMasterUpdate = (state: RootState) => state.masterUpdate;

export const masterUpdateReducer = masterUpdateSlice.reducer;
