import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { DoneOutline } from "@material-ui/icons";

interface DiscardDialogProps {
  open: boolean;
  isEdit?: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      verticalAlign: "middle",
      paddingRight: theme.spacing(1),
    },
  }),
);

export function DiscardDialog(props: DiscardDialogProps) {
  const classes = useStyles();
  const { open, isEdit, handleClose, handleSubmit } = props;
  const title = isEdit ? "CV連携設定：未反映確認" : "CV連携設定：未設定確認";

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <DoneOutline className={classes.icon} />
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          変更した内容が設定に反映されていませんが、変更内容を破棄しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleSubmit} color="primary">
          破棄する
        </Button>
      </DialogActions>
    </Dialog>
  );
}
