import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpPost } from "../../common/httpClient";
import { Status } from "../../common/status";
import { fetchCsrfToken } from "../../csrfSlice";
import { resetMessage } from "../../messagesSlice";
import { redirectTo } from "../../routerSlice";

interface loginState {
  status: Status;
}

interface LoginRequest {
  userId: string;
  password: string;
}

const initialState: loginState = {
  status: "idle",
};

export const login = createAsyncThunk<unknown, LoginRequest, ThunkApi>(
  "login/login",
  async (request, thunkApi) => {
    const path = "/login";
    await thunkApi.dispatch(fetchCsrfToken());

    const response = await httpPost<unknown>(path, request, thunkApi);
    thunkApi.dispatch(redirectTo("/masters"));
    thunkApi.dispatch(resetMessage());

    return response;
  },
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectLogin = (state: RootState) => state.login;

export const loginReducer = loginSlice.reducer;

export const USER_ID_KEY = "LOGIN_USER_ID";
