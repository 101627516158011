// https://firebase.google.com/docs/web/setup#add-sdks-initialize
import "firebase/analytics";

import firebase from "firebase/app";

import { isAdminStage, isDevStage, isProdStage } from "./config";

function initialize() {
  if (isDevStage) {
    return;
  }

  const appId = isProdStage
    ? "1:924683299950:web:633623ac4c0e8ce03b8a7f"
    : isAdminStage
    ? "1:924683299950:web:c24168d72a2544f63b8a7f"
    : "1:924683299950:web:30ac9e0a275bf0fe3b8a7f";

  const measurementId = isProdStage
    ? "G-QFWHJ8RM03"
    : isAdminStage
    ? "G-6J9GB9V745"
    : "G-JV8NJ3WFZ4";

  const firebaseConfig = {
    apiKey: "AIzaSyDw1CWlpw-5RwApY-dhai0ddECKKKqhmH4",
    authDomain: "tool-cv-converter.firebaseapp.com",
    projectId: "tool-cv-converter",
    storageBucket: "tool-cv-converter.appspot.com",
    messagingSenderId: "924683299950",
    appId,
    measurementId,
  };

  firebase.initializeApp(firebaseConfig);

  // https://firebase.google.com/docs/analytics/get-started?platform=web#add-sdk
  firebase.analytics();
}

initialize();
