import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../app/store";
import { httpGet } from "../../common/httpClient";
import { Status } from "../../common/status";

export interface MasterResponse {
  id: number;
  name: string;
  type: number;
  masterUrl: string;
}

interface MastersListState {
  status: Status;
  response?: MasterResponse[];
}

const initialState: MastersListState = {
  status: "idle",
};

export const fetchMastersList = createAsyncThunk(
  "masters/fetchMastersList",
  async (request, thunkApi) => {
    const path = "/masters";

    return httpGet<MasterResponse[]>(path, {}, thunkApi);
  },
);

export const mastersListSlice = createSlice({
  name: "mastersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMastersList.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchMastersList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.response = action.payload;
    });
    builder.addCase(fetchMastersList.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectMastersList = (state: RootState) => state.mastersList;

export const mastersListReducer = mastersListSlice.reducer;
