import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpPost } from "../../common/httpClient";
import { Status } from "../../common/status";

interface ManualExecutionRequest {
  startDate: string;
  endDate: string;
  id: number;
}

interface ManualExecutionResponse {
  message: string;
}

interface ManualExecutionState {
  status: Status;
  response?: ManualExecutionResponse;
}

const initialState: ManualExecutionState = {
  status: "idle",
};

export const start = createAsyncThunk<
  unknown,
  ManualExecutionRequest,
  ThunkApi
>("settings/manualExecution", async (request, thunkApi) => {
  const path = "/settings/" + request.id + "/start";

  return httpPost<unknown>(path, request, thunkApi);
});

export const manualExecutionSlice = createSlice({
  name: "manualExecution",
  initialState,
  reducers: {
    resetManualExecutionState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(start.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(start.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(start.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const manualExecution = (state: RootState) => state.manualExecution;

export const resetManualExecutionState =
  manualExecutionSlice.actions.resetManualExecutionState;

export const manualExecutionReducer = manualExecutionSlice.reducer;
