import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  ExitToAppOutlined,
  HelpOutline,
  LaunchOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCsrfToken, selectCsrf } from "../csrfSlice";
import { logout } from "../features/layout/logoutSlice";
import { USER_ID_KEY } from "../features/login/loginSlice";

const useStyles = makeStyles((theme) => ({
  headerIconButton: {
    backgroundColor: theme.palette.background.default,
  },
  headerListItemIcon: {
    minWidth: "30px",
  },
  headerListItemText: {
    fontSize: "0.75rem",
  },
  headerMenuItemUserId: {
    cursor: "default",
  },
}));

export function AccountAndHelpButtons() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { status: csrfStatus } = useSelector(selectCsrf);
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const helpMenuOpen = Boolean(helpMenuAnchorEl);

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const accountMenuOpen = Boolean(accountMenuAnchorEl);

  useEffect(() => {
    if (csrfStatus === "idle") {
      dispatch(fetchCsrfToken());
    }
  }, [csrfStatus, dispatch]);

  const handleHelpMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setHelpMenuAnchorEl(event.currentTarget);
  };

  const handleHelpMenuClose = () => {
    setHelpMenuAnchorEl(null);
  };

  const handleAccountMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem(USER_ID_KEY);
  };

  const helpMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={helpMenuAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={helpMenuOpen}
      onClose={handleHelpMenuClose}
    >
      <li>
        <MenuItem
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/document/d/1lkyhqofTkuXn-ZS1rLDG0DQsHo51PILjS4xO-jm-Kb4/edit?usp=sharing"
        >
          <ListItemIcon className={classes.headerListItemIcon}>
            <LaunchOutlined />
          </ListItemIcon>
          <ListItemText
            primary="マニュアル"
            classes={{ primary: classes.headerListItemText }}
          />
        </MenuItem>
      </li>
    </Menu>
  );

  const accountMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={accountMenuAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={accountMenuOpen}
      onClose={handleAccountMenuClose}
    >
      <MenuItem disableRipple={true} className={classes.headerMenuItemUserId}>
        <ListItemIcon className={classes.headerListItemIcon}>
          <AccountCircleOutlined />
        </ListItemIcon>
        <ListItemText
          primary={localStorage.getItem(USER_ID_KEY)}
          classes={{ primary: classes.headerListItemText }}
        />
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon className={classes.headerListItemIcon}>
          <ExitToAppOutlined />
        </ListItemIcon>
        <ListItemText
          primary="ログアウト"
          classes={{ primary: classes.headerListItemText }}
        />
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div>
        <IconButton
          color="default"
          onClick={handleHelpMenuOpen}
          className={clsx({ [classes.headerIconButton]: helpMenuOpen })}
        >
          <HelpOutline />
        </IconButton>
        {helpMenu}
      </div>
      <div>
        <IconButton
          color="default"
          edge="end"
          className={clsx({ [classes.headerIconButton]: accountMenuOpen })}
          onClick={handleAccountMenuOpen}
        >
          <AccountCircleOutlined />
        </IconButton>
        {accountMenu}
      </div>
    </>
  );
}
