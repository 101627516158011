import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, ThunkApi } from "../../app/store";
import { httpPost } from "../../common/httpClient";
import { Status } from "../../common/status";
import { redirectTo } from "../../routerSlice";
import { fetchSettingsList } from "./settingsListSlice";
import { AdebisSetting, GaSetting, SettingPostBase } from "./settingTypes";

interface SettingCreateState {
  status: Status;
}

interface GaSettingCreateRequest extends SettingPostBase {
  gaSetting: GaSetting;
}

interface AdebisSettingCreateRequest extends SettingPostBase {
  adebisSetting: AdebisSetting;
}

type SettingCreateRequest = GaSettingCreateRequest | AdebisSettingCreateRequest;

const initialState: SettingCreateState = {
  status: "idle",
};

export const createSetting = createAsyncThunk<
  unknown,
  SettingCreateRequest,
  ThunkApi
>("settings/createSetting", async (request, thunkApi) => {
  const path = "/settings";
  const response = await httpPost<unknown>(path, request, thunkApi);
  void thunkApi.dispatch(fetchSettingsList());
  thunkApi.dispatch(redirectTo("/settings"));

  return response;
});

export const settingCreateSlice = createSlice({
  name: "settingCreate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createSetting.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createSetting.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(createSetting.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectSettingCreate = (state: RootState) => state.settingCreate;

export const settingCreateReducer = settingCreateSlice.reducer;
