import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import {
  CheckBoxOutlined,
  CheckCircleOutlined,
  Close,
  DoneOutline,
  NotesOutlined,
  PeopleOutlined,
} from "@material-ui/icons";
import arrayMutators from "final-form-arrays";
import { forwardRef, Ref, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { AccountAndHelpButtons } from "../../common/AccountAndHelpButtons";
import {
  helperText,
  isError,
  shrink,
  startIconColor,
} from "../../common/textFieldProps";
import { containedUser, isUserIds, required } from "../../common/validation";
import { isAdmin } from "../../config";
import { USER_ID_KEY } from "../login/loginSlice";
import { createMaster, selectMasterCreate } from "./masterCreateSlice";
import { masterTypes } from "./masterType";

interface MasterCreateForm {
  masterName: string;
  masterType: number;
  masterOwners: string;
}

interface DiscardDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const useDiscardDialogStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      verticalAlign: "middle",
      paddingRight: theme.spacing(1),
    },
  }),
);

function DiscardDialog(props: DiscardDialogProps) {
  const classes = useDiscardDialogStyles();
  const { open, handleClose, handleSubmit } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <DoneOutline className={classes.icon} />
        CV連携マスタ：未設定確認
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          変更した内容が設定に反映されていませんが、変更内容を破棄しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleSubmit} color="primary">
          破棄する
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundColor: isAdmin ? "#f3e5d4" : "#f5f5f5",
      color: "#202020",
    },
    statusSwitch: {
      paddingRight: theme.spacing(3),
    },
    containerItem: {
      padding: theme.spacing(2),
    },
  }),
);

const Transition = forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: Ref<unknown>,
  ) => {
    return <Slide {...props} direction="left" ref={ref} />;
  },
);

export function MasterCreate() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector(selectMasterCreate);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const open = pathname === "/masters/new" || pathname === "/masters/new/";
  const userId = localStorage.getItem(USER_ID_KEY) ?? "";

  const handleClose = () => {
    setOpenDiscardDialog(false);
    history.push("/masters");
  };

  const handleDiscardDialogOpen = () => {
    setOpenDiscardDialog(true);
  };

  const handleDiscardDialogClose = () => {
    setOpenDiscardDialog(false);
  };

  const createMasterForm = (values: MasterCreateForm) => {
    const { masterName, masterType, masterOwners } = values;

    const owners = Array.from(
      new Set(masterOwners.split(",").map((owner) => owner.trim())),
    ).map((owner) => ({ userId: owner }));

    dispatch(
      createMaster({
        masterName,
        masterType,
        owners,
      }),
    );
  };

  const handleSubmit = (values: MasterCreateForm) => {
    if (masterTypes.some((type) => type.id === values.masterType)) {
      createMasterForm(values);
    } else {
      throw new Error("不明なフォームデータが入力されました。");
    }
  };

  return (
    <>
      <DiscardDialog
        open={openDiscardDialog}
        handleClose={handleDiscardDialogClose}
        handleSubmit={handleClose}
      />
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <Form<MasterCreateForm>
          onSubmit={(values) => handleSubmit(values)}
          mutators={{ ...arrayMutators }}
          render={({
            handleSubmit,
            hasValidationErrors,
            pristine,
            values: { masterType },
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Tooltip title="CV連携マスタの作成をキャンセル">
                        <IconButton
                          edge="start"
                          color="inherit"
                          disabled={status === "loading"}
                          onClick={handleDiscardDialogOpen}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={3}>
                      <Typography variant="h6">
                        {"CV連携マスタ新規作成" + (isAdmin ? "管理画面" : "")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Tooltip title="CV連携マスタを作成する">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={
                            pristine ||
                            hasValidationErrors ||
                            status === "loading"
                          }
                          disableFocusRipple={true}
                          startIcon={<CheckCircleOutlined />}
                          endIcon={
                            status === "loading" ? (
                              <CircularProgress size={24} />
                            ) : undefined
                          }
                        >
                          設定する
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <AccountAndHelpButtons />
                </Toolbar>
              </AppBar>

              <Box padding="24px" margin="auto">
                <Grid container>
                  <Grid
                    className={classes.containerItem}
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                  >
                    <Field<string>
                      name="masterName"
                      validate={required}
                      render={({ input, meta }) => (
                        <TextField
                          {...input}
                          label="CV連携マスタ名"
                          error={isError(meta)}
                          helperText={helperText(meta)}
                          fullWidth
                          InputLabelProps={{ shrink: shrink(meta) }}
                          InputProps={{
                            startAdornment: (
                              <NotesOutlined color={startIconColor(meta)} />
                            ),
                          }}
                          disabled={status === "loading"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    className={classes.containerItem}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                  >
                    <Field<string>
                      name="masterType"
                      validate={required}
                      render={({ input, meta }) => (
                        <div>
                          <TextField
                            {...input}
                            label="マスタ種別"
                            select
                            error={isError(meta)}
                            helperText={helperText(meta)}
                            fullWidth
                            InputLabelProps={{ shrink: shrink(meta) }}
                            InputProps={{
                              startAdornment: (
                                <CheckBoxOutlined
                                  color={startIconColor(meta)}
                                />
                              ),
                            }}
                            disabled={status === "loading"}
                          >
                            {masterTypes.map((masterType) => (
                              <MenuItem
                                key={masterType.id}
                                value={masterType.id}
                              >
                                {masterType.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    className={classes.containerItem}
                    item
                    xs={12}
                    sm={6}
                    xl={4}
                  >
                    <Field<string>
                      name="masterOwners"
                      defaultValue={userId}
                      validate={(value) =>
                        required(value) ??
                        containedUser(value, userId) ??
                        isUserIds(value)
                      }
                      render={({ input, meta }) => (
                        <TextField
                          {...input}
                          label="マスタ管理ユーザー"
                          error={isError(meta)}
                          helperText={helperText(
                            meta,
                            "カンマ区切りで複数入力可",
                          )}
                          fullWidth
                          InputLabelProps={{ shrink: shrink(meta) }}
                          InputProps={{
                            startAdornment: (
                              <PeopleOutlined color={startIconColor(meta)} />
                            ),
                          }}
                          disabled={status === "loading"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        />
      </Dialog>
    </>
  );
}
