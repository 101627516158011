import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../app/store";
import { httpPost } from "../../common/httpClient";
import { Status } from "../../common/status";
import { redirectTo } from "../../routerSlice";

interface logoutState {
  status: Status;
}

const initialState: logoutState = {
  status: "idle",
};

export const logout = createAsyncThunk(
  "logout/logout",
  async (request, thunkApi) => {
    const path = "/logout";
    const response = await httpPost<unknown>(path, {}, thunkApi);
    thunkApi.dispatch(redirectTo("/login"));

    return response;
  },
);

export const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.status = "failed";
      console.error(action.error.message);
    });
  },
});

export const selectLogout = (state: RootState) => state.logout;

export const logoutReducer = logoutSlice.reducer;
