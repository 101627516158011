import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { CheckBoxOutlined, NotesOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";

import {
  helperText,
  isError,
  shrink,
  startIconColor,
} from "../../common/textFieldProps";
import { required } from "../../common/validation";
import { AdebisForm } from "./AdebisForm";
import { GaForm } from "./GaForm";
import { selectSettingCreate } from "./settingCreateSlice";
import { selectSettingUpdate } from "./settingUpdateSlice";

interface SettingFormProps {
  amsToolId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  push: (...args: any[]) => any;
  isEdit?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    containerItem: {
      padding: theme.spacing(2),
    },
  }),
);

export function SettingForm(props: SettingFormProps) {
  const classes = useStyles();
  const { amsToolId, push, isEdit } = props;

  const [adebisInitialized, setAdebisInitialized] = useState(false);
  const [gaInitialized, setGaInitialized] = useState(false);
  const { status: settingCreateStatus } = useSelector(selectSettingCreate);
  const { status: settingUpdateStatus } = useSelector(selectSettingUpdate);

  useEffect(() => {
    if (amsToolId === 9) {
      setAdebisInitialized(true);
    } else if (amsToolId === 23) {
      setGaInitialized(true);
    }
  }, [amsToolId]);

  return (
    <Box padding="24px" margin="auto">
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="name"
            validate={required}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="CV連携設定名"
                error={isError(meta)}
                helperText={helperText(meta)}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <NotesOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>
        <Grid
          className={classes.containerItem}
          item
          xs={12}
          sm={6}
          md={4}
          xl={3}
        >
          <Field<number>
            name="amsToolId"
            validate={required}
            render={({ input, meta }) => (
              <div>
                <TextField
                  {...input}
                  label="計測ツール種別"
                  select
                  error={isError(meta)}
                  helperText={helperText(meta)}
                  fullWidth
                  InputLabelProps={{ shrink: shrink(meta) }}
                  InputProps={{
                    startAdornment: (
                      <CheckBoxOutlined color={startIconColor(meta)} />
                    ),
                  }}
                  disabled={isEdit || settingCreateStatus === "loading"}
                >
                  <MenuItem value={23}>Google Analytics</MenuItem>
                  <MenuItem value={9}>AD EBiS</MenuItem>
                </TextField>
              </div>
            )}
          />
        </Grid>
      </Grid>
      {amsToolId === 9 ? (
        <AdebisForm
          push={push}
          isEdit={isEdit}
          initialized={adebisInitialized}
        />
      ) : undefined}
      {amsToolId === 23 ? (
        <GaForm push={push} isEdit={isEdit} initialized={gaInitialized} />
      ) : undefined}
    </Box>
  );
}
