import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import {
  CheckBoxOutlined,
  CheckCircleOutlined,
  Close,
  DeleteOutlined,
  DoneOutline,
  NotesOutlined,
  PeopleOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import arrayMutators from "final-form-arrays";
import { forwardRef, Ref, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AccountAndHelpButtons } from "../../common/AccountAndHelpButtons";
import {
  helperText,
  isError,
  shrink,
  startIconColor,
} from "../../common/textFieldProps";
import { containedUser, isUserIds, required } from "../../common/validation";
import { isAdmin } from "../../config";
import { USER_ID_KEY } from "../login/loginSlice";
import { DeleteDialog } from "./DeleteDialog";
import { fetchMasterShow, selectMasterShow } from "./masterShowSlice";
import { masterTypes } from "./masterType";
import { selectMasterUpdate, updateMaster } from "./masterUpdateSlice";

interface MasterUpdateForm {
  masterName: string;
  masterType: number;
  masterUrl: string;
  masterOwners: string;
}

interface Params {
  id?: string;
}

interface DiscardDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const useDiscardDialogStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      verticalAlign: "middle",
      paddingRight: theme.spacing(1),
    },
  }),
);

function DiscardDialog(props: DiscardDialogProps) {
  const classes = useDiscardDialogStyles();
  const { open, handleClose, handleSubmit } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <DoneOutline className={classes.icon} />
        CV連携マスタ：未設定確認
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          変更した内容が設定に反映されていませんが、変更内容を破棄しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleSubmit} color="primary">
          破棄する
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundColor: isAdmin ? "#f3e5d4" : "#f5f5f5",
      color: "#202020",
    },
    statusSwitch: {
      paddingRight: theme.spacing(3),
    },
    containerItem: {
      padding: theme.spacing(2),
    },
    textSpan: {
      display: "inline-block",
      maxWidth: "100%",
    },
    urlButton: {
      maxWidth: "750px",
    },
    progress: {
      paddingTop: theme.spacing(5),
    },
    truncate: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    sheetUrl: {
      color: "#0000008A",
    },
  }),
);

const Transition = forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: Ref<unknown>,
  ) => {
    return <Slide {...props} direction="left" ref={ref} />;
  },
);

export function MasterUpdate() {
  const classes = useStyles();
  const params = useParams<Params>();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector(selectMasterUpdate);
  const { status: showStatus, response: showResponse } =
    useSelector(selectMasterShow);

  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const id = Number(params.id);
  const open =
    pathname === `/masters/${id}/edit` || pathname === `/masters/${id}/edit`;

  const userId = localStorage.getItem(USER_ID_KEY) ?? "";

  const initialValues = ((): Record<string, never> | MasterUpdateForm => {
    if (!showResponse) {
      return {};
    } else {
      const { name, type, masterUrl, owners } = showResponse;

      return {
        masterName: name,
        masterType: type,
        masterUrl,
        masterOwners: owners.map((owner) => owner.userId).join(","),
      };
    }
  })();

  const handleClose = () => {
    setOpenDiscardDialog(false);
    history.push("/masters");
  };

  const handleDiscardDialogOpen = () => {
    setOpenDiscardDialog(true);
  };

  const handleDiscardDialogClose = () => {
    setOpenDiscardDialog(false);
  };

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const updateMasterForm = (values: MasterUpdateForm) => {
    const { masterName, masterOwners } = values;

    const owners = Array.from(
      new Set(masterOwners.split(",").map((owner) => owner.trim())),
    ).map((owner) => ({ userId: owner }));

    dispatch(
      updateMaster({
        masterId: id,
        masterName,
        owners,
      }),
    );
  };

  const handleSubmit = (values: MasterUpdateForm) => {
    if (
      values.masterType &&
      values.masterType === showResponse?.type &&
      masterTypes.some((type) => type.id === values.masterType)
    ) {
      updateMasterForm(values);
    } else {
      throw new Error("不明なフォームデータが入力されました。");
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchMasterShow({ id }));
    }
  }, [dispatch, open, id]);

  return (
    <>
      <DiscardDialog
        open={openDiscardDialog}
        handleClose={handleDiscardDialogClose}
        handleSubmit={handleClose}
      />
      <DeleteDialog
        open={openDeleteDialog}
        id={id}
        handleClose={handleDeleteDialogClose}
      />
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <Form<MasterUpdateForm>
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
          mutators={{ ...arrayMutators }}
          render={({
            handleSubmit,
            hasValidationErrors,
            pristine,
            values: { masterType },
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item>
                      <Tooltip title="CV連携マスタの編集をキャンセル">
                        <IconButton
                          edge="start"
                          color="inherit"
                          disabled={status === "loading"}
                          onClick={
                            pristine ? handleClose : handleDiscardDialogOpen
                          }
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={3}>
                      <Typography variant="h6">
                        {"CV連携マスタ編集" + (isAdmin ? "｜管理画面" : "")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="CV連携マスタを更新">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={
                            pristine ||
                            hasValidationErrors ||
                            status === "loading"
                          }
                          disableFocusRipple={true}
                          startIcon={<CheckCircleOutlined />}
                          endIcon={
                            status === "loading" ? (
                              <CircularProgress size={24} />
                            ) : undefined
                          }
                        >
                          設定する
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={
                          isAdmin
                            ? "管理画面では削除できません"
                            : "CV連携マスタを削除"
                        }
                      >
                        <Button
                          disabled={status === "loading"}
                          disableFocusRipple={true}
                          startIcon={<DeleteOutlined />}
                          onClick={isAdmin ? undefined : handleDeleteDialogOpen}
                        >
                          削除
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <AccountAndHelpButtons />
                </Toolbar>
              </AppBar>
              {showStatus === "idle" || showStatus === "loading" ? (
                <Grid
                  className={classes.progress}
                  container
                  justifyContent="center"
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                <Box padding="24px" margin="auto">
                  <Grid container>
                    <Grid
                      className={classes.containerItem}
                      item
                      xs={12}
                      sm={6}
                      xl={4}
                    >
                      <Field<string>
                        name="masterName"
                        validate={required}
                        render={({ input, meta }) => (
                          <TextField
                            {...input}
                            label="CV連携マスタ名"
                            error={isError(meta)}
                            helperText={helperText(meta)}
                            fullWidth
                            InputLabelProps={{ shrink: shrink(meta) }}
                            InputProps={{
                              startAdornment: (
                                <NotesOutlined color={startIconColor(meta)} />
                              ),
                            }}
                            disabled={status === "loading"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      className={classes.containerItem}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      xl={3}
                    >
                      <Field<string>
                        name="masterType"
                        validate={required}
                        render={({ input, meta }) => (
                          <div>
                            <TextField
                              {...input}
                              disabled
                              label="マスタ種別"
                              select
                              error={isError(meta)}
                              helperText={helperText(meta)}
                              fullWidth
                              InputLabelProps={{ shrink: shrink(meta) }}
                              InputProps={{
                                startAdornment: (
                                  <CheckBoxOutlined
                                    color={startIconColor(meta)}
                                  />
                                ),
                              }}
                            >
                              {masterTypes.map((masterType) => (
                                <MenuItem
                                  key={masterType.id}
                                  value={masterType.id}
                                >
                                  {masterType.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container>
                    <Grid
                      className={classes.containerItem}
                      item
                      xs={12}
                      md={12}
                    >
                      <Box className={classes.sheetUrl}>
                        スプレッドシート（リンク先から直接編集してください。設定ボタンを押す必要はありません。）
                      </Box>
                    </Grid>
                    <Grid
                      className={classes.containerItem}
                      item
                      xs={12}
                      md={12}
                    >
                      <Button
                        href={initialValues.masterUrl}
                        target="_blank"
                        rel="noopener"
                        color="primary"
                        size="small"
                        className={classes.urlButton}
                        fullWidth
                      >
                        <span
                          className={clsx(classes.textSpan, classes.truncate)}
                        >
                          {initialValues.masterUrl}
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider />

                  <Grid container>
                    <Grid
                      className={classes.containerItem}
                      item
                      xs={12}
                      sm={6}
                      xl={4}
                    >
                      <Field<string>
                        name="masterOwners"
                        defaultValue={userId}
                        validate={(value) =>
                          required(value) ??
                          containedUser(value, userId) ??
                          isUserIds(value)
                        }
                        render={({ input, meta }) => (
                          <TextField
                            {...input}
                            label="マスタ管理ユーザー"
                            error={isError(meta)}
                            helperText={helperText(
                              meta,
                              "カンマ区切りで複数入力可",
                            )}
                            fullWidth
                            InputLabelProps={{ shrink: shrink(meta) }}
                            InputProps={{
                              startAdornment: (
                                <PeopleOutlined color={startIconColor(meta)} />
                              ),
                            }}
                            disabled={status === "loading"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </form>
          )}
        />
      </Dialog>
    </>
  );
}
