import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Link,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline,
  CheckBoxOutlined,
  DeleteOutline,
  FilterList,
  NotesOutlined,
  PeopleOutlined,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import { Field, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";

import {
  helperText,
  isError,
  shrink,
  startIconColor,
} from "../../common/textFieldProps";
import {
  containedUser,
  isNumber,
  isResultPoint,
  isToolAccountId,
  isUserIds,
  required,
} from "../../common/validation";
import disabledGoogleSignInButton from "../../images/btn_google_signin_light_disabled_web.png";
import googleSignInButton from "../../images/btn_google_signin_light_normal_web.png";
import { USER_ID_KEY } from "../login/loginSlice";
import {
  fetchMastersList,
  selectMastersList,
} from "../masters/mastersListSlice";
import { selectSettingCreate } from "./settingCreateSlice";
import { selectSettingShow, SettingShowResponse } from "./settingShowSlice";
import type { CvType, SettingFormBase } from "./settingTypes";
import { selectSettingUpdate } from "./settingUpdateSlice";

export interface GaFormType extends SettingFormBase {
  amsToolId: 23;
  amsToolAccountId: string;
  propertyId: number;
  isGa360: number; // select field の value は boolean にできない
  gaCvs: Cv[];
  gaDimensions: GaDimensions;
  gaFilters: Filter[];
  authorizationCode: string;
  masterId: number;
  settingOwners: string;
  alertDestinations: string;
}

interface Cv {
  metric: string;
  resultPointName: string;
  type: CvType;
}

interface GaDimensions {
  dimensionA: string;
  dimensionB: string;
  dimensionC: string;
  dimensionD: string;
  dimensionE: string;
  dimensionF: string;
}

const dimensionLabelArray = ["A", "B", "C", "D", "E", "F"];

interface Filter {
  dimension: string;
  filterType: "include" | "exclude";
  comparisonTarget: string;
  matchType: "equal" | "regex";
}

const useStyles = makeStyles((theme) =>
  createStyles({
    containerItem: {
      padding: theme.spacing(2),
    },
    equal: {
      fontSize: "40px",
      fontWeight: "lighter",
      paddingLeft: theme.spacing(2),
    },
  }),
);

interface MetricRowProps {
  name: string;
  handleDelete?: () => void;
}

function MetricRow(props: MetricRowProps) {
  const classes = useStyles();
  const { name, handleDelete } = props;
  const { status: settingCreateStatus } = useSelector(selectSettingCreate);
  const { status: settingUpdateStatus } = useSelector(selectSettingUpdate);

  return (
    <Grid container alignItems="center">
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Grid container alignItems="center">
          <Grid item>
            <NotesOutlined />
          </Grid>
          <Grid item xs={9}>
            <Field<string>
              name={`${name}.metric`}
              validate={required}
              render={({ input, meta }) => (
                <TextField
                  {...input}
                  label="指標"
                  error={meta.invalid && meta.initial !== undefined}
                  helperText={helperText(meta)}
                  fullWidth
                  InputLabelProps={{ shrink: shrink(meta) }}
                  disabled={
                    settingCreateStatus === "loading" ||
                    settingUpdateStatus === "loading"
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <span className={classes.equal}>=</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Field<string>
          name={`${name}.resultPointName`}
          validate={(value) => required(value) ?? isResultPoint(value)}
          render={({ input, meta }) => (
            <TextField
              {...input}
              label="CV地点名"
              error={isError(meta)}
              helperText={helperText(meta)}
              fullWidth
              InputLabelProps={{ shrink: shrink(meta) }}
              disabled={
                settingCreateStatus === "loading" ||
                settingUpdateStatus === "loading"
              }
            />
          )}
        />
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Field<string>
          name={`${name}.type`}
          validate={required}
          render={({ input, meta }) => (
            <TextField
              {...input}
              label="CV地点タイプ"
              select
              error={isError(meta)}
              helperText={helperText(meta)}
              fullWidth
              InputLabelProps={{ shrink: shrink(meta) }}
              disabled={
                settingCreateStatus === "loading" ||
                settingUpdateStatus === "loading"
              }
            >
              <MenuItem value="cv_t">件数（のべ）</MenuItem>
              <MenuItem value="cv_u">件数（ユニーク）</MenuItem>
              <MenuItem value="sales">売上</MenuItem>
            </TextField>
          )}
        />
      </Grid>
      {handleDelete ? (
        <Grid className={classes.containerItem} item xs={12} sm={3}>
          <Button
            variant="contained"
            startIcon={<DeleteOutline />}
            onClick={handleDelete}
            disabled={
              settingCreateStatus === "loading" ||
              settingUpdateStatus === "loading"
            }
          >
            削除
          </Button>
        </Grid>
      ) : undefined}
    </Grid>
  );
}

interface FilterRowProps {
  name: string;
  handleDelete: () => void;
}

function FilterRow(props: FilterRowProps) {
  const classes = useStyles();
  const { name, handleDelete } = props;
  const { status: settingCreateStatus } = useSelector(selectSettingCreate);
  const { status: settingUpdateStatus } = useSelector(selectSettingUpdate);

  return (
    <Grid container alignItems="center">
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3} lg={2}>
        <Grid container alignItems="center">
          <Grid item>
            <FilterList />
          </Grid>
          <Grid item xs={10}>
            <Field<string>
              name={`${name}.filterType`}
              validate={required}
              render={({ input, meta }) => (
                <TextField
                  {...input}
                  label="フィルタタイプ"
                  select
                  error={isError(meta)}
                  helperText={helperText(meta)}
                  fullWidth
                  InputLabelProps={{ shrink: shrink(meta) }}
                  disabled={
                    settingCreateStatus === "loading" ||
                    settingUpdateStatus === "loading"
                  }
                >
                  <MenuItem value="include">一致</MenuItem>
                  <MenuItem value="exclude">除外</MenuItem>
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Field<string>
          name={`${name}.dimension`}
          validate={required}
          render={({ input, meta }) => (
            <TextField
              {...input}
              label="ディメンション"
              error={meta.invalid && meta.initial !== undefined}
              helperText={helperText(meta)}
              fullWidth
              InputLabelProps={{ shrink: shrink(meta) }}
              disabled={
                settingCreateStatus === "loading" ||
                settingUpdateStatus === "loading"
              }
            />
          )}
        />
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3} lg={2}>
        <Field<string>
          name={`${name}.matchType`}
          validate={required}
          render={({ input, meta }) => (
            <TextField
              {...input}
              label="比較タイプ"
              select
              error={isError(meta)}
              helperText={helperText(meta)}
              fullWidth
              InputLabelProps={{ shrink: shrink(meta) }}
              disabled={
                settingCreateStatus === "loading" ||
                settingUpdateStatus === "loading"
              }
            >
              <MenuItem value="equal">完全一致</MenuItem>
              <MenuItem value="regex">正規表現</MenuItem>
            </TextField>
          )}
        />
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={6} md={3}>
        <Field<string>
          name={`${name}.comparisonTarget`}
          validate={required}
          render={({ input, meta }) => (
            <TextField
              {...input}
              label="比較する文字列"
              error={isError(meta)}
              helperText={helperText(meta)}
              fullWidth
              InputLabelProps={{ shrink: shrink(meta) }}
              disabled={
                settingCreateStatus === "loading" ||
                settingUpdateStatus === "loading"
              }
            />
          )}
        />
      </Grid>
      <Grid className={classes.containerItem} item xs={12} sm={3} md={2} lg={2}>
        <Button
          variant="contained"
          startIcon={<DeleteOutline />}
          onClick={handleDelete}
          disabled={
            settingCreateStatus === "loading" ||
            settingUpdateStatus === "loading"
          }
        >
          削除
        </Button>
      </Grid>
    </Grid>
  );
}

interface CustomDimensionFieldsProps {
  isEdit: boolean | undefined;
  showResponse: SettingShowResponse | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>;
}

function CustomDimensionFields(props: CustomDimensionFieldsProps) {
  const classes = useStyles();
  const { status: settingCreateStatus } = useSelector(selectSettingCreate);
  const { status: settingUpdateStatus } = useSelector(selectSettingUpdate);
  const { isEdit, showResponse, values } = props;

  const isGaDimensionValuesDifferentFromInitialValues = (
    showResponseGaDimensions: GaDimensions,
    valuesGaDimensions: GaDimensions,
  ) => {
    for (const label of dimensionLabelArray) {
      if (!(`dimension${label}` in values.gaDimensions)) {
        values.gaDimensions[`dimension${label}`] = null;
      }
    }

    return Object.entries(showResponseGaDimensions)
      .map(([key, value]) => {
        return valuesGaDimensions[key as keyof GaDimensions] === value;
      })
      .includes(false);
  };

  return (
    <>
      {!isEdit ||
      (isEdit &&
        showResponse?.amsToolId === 23 &&
        isGaDimensionValuesDifferentFromInitialValues(
          showResponse.gaSetting.gaDimensions,
          values.gaDimensions,
        )) ? (
        <Alert severity="warning">
          選択したディメンションとマスタの各列の対応関係が正しいことを確認してください
        </Alert>
      ) : (
        <></>
      )}
      <Grid container>
        {dimensionLabelArray.map((element) => (
          <Grid
            className={classes.containerItem}
            item
            xs={12}
            sm={6}
            lg={4}
            key={element}
          >
            <Field<string>
              name={`gaDimensions.dimension${element}`}
              render={({ input, meta }) => (
                <TextField
                  {...input}
                  label={`マスタ${element}列のディメンション`}
                  error={meta.invalid && meta.initial !== undefined}
                  helperText={helperText(meta)}
                  fullWidth
                  InputLabelProps={{ shrink: shrink(meta) }}
                  InputProps={{
                    startAdornment: (
                      <NotesOutlined color={startIconColor(meta)} />
                    ),
                  }}
                  disabled={
                    settingCreateStatus === "loading" ||
                    settingUpdateStatus === "loading"
                  }
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
      <Divider />
    </>
  );
}

interface GoogleSignInButtonProps {
  isEdit?: boolean;
}

function GoogleSignInButton(props: GoogleSignInButtonProps) {
  const { isEdit } = props;

  // 環境毎にリダイレクトURIのパラメータを変える
  const url =
    "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=924683299950-6vg535or20bk7lu1bj4vjq02i181ts38.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&prompt=consent&access_type=offline&redirect_uri=" +
    window.location.origin +
    "/settings/auth";

  const { status: settingCreateStatus } = useSelector(selectSettingCreate);

  return isEdit || settingCreateStatus === "loading" ? (
    <img src={disabledGoogleSignInButton} alt="Googleにログイン" />
  ) : (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      <img src={googleSignInButton} alt="Googleにログイン" />
    </Link>
  );
}

interface AuthorizationCodeInstructionProps {
  isEdit?: boolean;
}

function AuthorizationCodeInstruction(
  props: AuthorizationCodeInstructionProps,
) {
  const { isEdit } = props;
  const text = isEdit
    ? "認可コードの取得は不要です。Google Analyticsへのログインアカウントを変更したい場合はCV連携設定を新規作成してください。"
    : "Google Analyticsへの閲覧権限を持ったGoogleアカウントでログインし、認可コードを取得してください。";

  return <Typography variant="subtitle2">{text}</Typography>;
}

interface GaFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  push: (...args: any[]) => any;
  isEdit?: boolean;
  initialized?: boolean;
}

export function GaForm(props: GaFormProps) {
  const { status, response } = useSelector(selectMastersList);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { push, isEdit, initialized } = props;
  const masters = response?.filter(({ type }) => type === 5);

  const userId = localStorage.getItem(USER_ID_KEY) ?? "";
  const { status: settingCreateStatus } = useSelector(selectSettingCreate);
  const { status: settingUpdateStatus } = useSelector(selectSettingUpdate);
  const { response: showResponse } = useSelector(selectSettingShow);
  const { values } = useFormState();

  const handleAddMetricButtonClick = () => {
    push("gaCvs", undefined);
  };

  const handleAddFilterButtonClick = () => {
    push("gaFilters", undefined);
  };

  if (
    isEdit &&
    showResponse !== undefined &&
    masters !== undefined &&
    !masters.map((x) => x.id).includes(showResponse.masterId)
  ) {
    masters.push({
      id: showResponse.masterId,
      name: "（権限のないマスタが設定されています）",
      masterUrl: "",
      type: 1,
    });
  }
  useEffect(() => {
    dispatch(fetchMastersList());
  }, [dispatch]);

  useEffect(() => {
    if (!isEdit && !initialized) {
      push("gaCvs", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === "loading") {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<number>
            name="masterId"
            validate={required}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="CV連携マスタ"
                select
                error={isError(meta)}
                helperText={helperText(meta)}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <CheckBoxOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              >
                {!masters || masters.length === 0 ? (
                  <MenuItem disabled>指定可能なマスタがありません</MenuItem>
                ) : (
                  masters.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))
                )}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Divider />

      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="amsToolAccountId"
            validate={(value) => required(value) ?? isToolAccountId(value)}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="計測ツールアカウントID"
                error={isError(meta)}
                helperText={helperText(meta)}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <NotesOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<number>
            name="propertyId"
            validate={(value) => required(value) ?? isNumber(String(value))}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="プロパティID"
                error={isError(meta)}
                helperText={helperText(meta)}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <NotesOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>

        <Grid
          className={classes.containerItem}
          item
          xs={12}
          sm={6}
          md={4}
          xl={3}
        >
          <Field<number>
            name="isGa360"
            validate={required}
            render={({ input, meta }) => (
              <div>
                <TextField
                  {...input}
                  label="GA360の利用"
                  select
                  error={isError(meta)}
                  helperText={helperText(meta)}
                  fullWidth
                  InputLabelProps={{ shrink: shrink(meta) }}
                  InputProps={{
                    startAdornment: (
                      <CheckBoxOutlined color={startIconColor(meta)} />
                    ),
                  }}
                  disabled={
                    settingCreateStatus === "loading" ||
                    settingUpdateStatus === "loading"
                  }
                >
                  <MenuItem value={0}>利用していない</MenuItem>
                  <MenuItem value={1}>利用している</MenuItem>
                </TextField>
              </div>
            )}
          />
        </Grid>
      </Grid>
      <Divider />

      <CustomDimensionFields
        isEdit={isEdit}
        showResponse={showResponse}
        values={values}
      />

      <FieldArray name="gaCvs">
        {({ fields }) =>
          fields.map((name, index) => (
            <MetricRow
              key={name}
              name={name}
              handleDelete={
                index === 0 ? undefined : () => fields.remove(index)
              }
            />
          ))
        }
      </FieldArray>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline />}
            onClick={handleAddMetricButtonClick}
            disabled={
              settingCreateStatus === "loading" ||
              settingUpdateStatus === "loading"
            }
          >
            CV地点を追加
          </Button>
        </Grid>
      </Grid>
      <Divider />

      <FieldArray name="gaFilters">
        {({ fields }) =>
          fields.map((name, index) => (
            <FilterRow
              key={name}
              name={name}
              handleDelete={() => fields.remove(index)}
            />
          ))
        }
      </FieldArray>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline />}
            onClick={handleAddFilterButtonClick}
            disabled={
              settingCreateStatus === "loading" ||
              settingUpdateStatus === "loading"
            }
          >
            フィルタを追加
          </Button>
        </Grid>
      </Grid>
      <Divider />

      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="settingOwners"
            defaultValue={userId}
            validate={(value) =>
              required(value) ??
              containedUser(value, userId) ??
              isUserIds(value)
            }
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="設定管理ユーザー"
                error={isError(meta)}
                helperText={helperText(meta, "カンマ区切りで複数入力可")}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <PeopleOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="alertDestinations"
            defaultValue={userId}
            validate={(value) => required(value) ?? isUserIds(value)}
            render={({ input, meta }) => (
              <TextField
                {...input}
                label="エラー通知先ユーザー"
                error={isError(meta)}
                helperText={helperText(meta, "カンマ区切りで複数入力可")}
                fullWidth
                InputLabelProps={{ shrink: shrink(meta) }}
                InputProps={{
                  startAdornment: (
                    <PeopleOutlined color={startIconColor(meta)} />
                  ),
                }}
                disabled={
                  settingCreateStatus === "loading" ||
                  settingUpdateStatus === "loading"
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Divider />

      <Grid container>
        <Grid className={classes.containerItem} item xs={12}>
          <AuthorizationCodeInstruction isEdit={isEdit} />
        </Grid>
        <Grid className={classes.containerItem} item xs={12}>
          <GoogleSignInButton isEdit={isEdit} />
        </Grid>
        <Grid className={classes.containerItem} item xs={12} sm={6} xl={4}>
          <Field<string>
            name="authorizationCode"
            validate={(value) => (isEdit ? undefined : required(value))}
            render={({ input, meta }) => {
              const text = isEdit
                ? "認可コードは編集できません"
                : "認可コードを生成したら10分以内に設定の作成を完了してください";

              return (
                <TextField
                  {...input}
                  label="認可コード"
                  error={isError(meta)}
                  helperText={helperText(meta, text)}
                  fullWidth
                  InputLabelProps={{ shrink: shrink(meta) }}
                  InputProps={{
                    startAdornment: (
                      <NotesOutlined color={startIconColor(meta)} />
                    ),
                  }}
                  disabled={isEdit || settingCreateStatus === "loading"}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
